<template>
  <div class="media media--small">
    <div class="media__left">
      <div :class="['status', `status--${type}`]">
        <Icon :id="icon" class="icon--75" />
      </div>
    </div>
    <div class="media__body">
      <slot />
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue';

export default {
  components: {
    Icon,
  },

  props: {
    type: {
      type: String,
      default: null,
    },
  },

  computed: {
    icon() {
      switch (this.type) {
        case 'success':
          return 'tick';
        case 'error':
          return 'cross';
        default:
          return null;
      }
    },
  },
};
</script>
