import { ref } from 'vue';
import axios from 'axios';

/**
 * Query the database of cities to check if gas is available at the given postal code.
 */
export default function useCitySearch() {
  const location = ref(null);
  const errors = ref([]);

  const search = (zipCode) => {
    errors.value = [];
    location.value = null;

    return new Promise((resolve, reject) => {
      const zipCodeAsNumber = parseInt(zipCode);

      if (isNaN(zipCodeAsNumber) || zipCodeAsNumber > 9999) {
        errors.value.push(
          gettext('Un code postal doit se composer de 4 chiffres.')
        );
        reject();
        return;
      } else if (zipCodeAsNumber < 1000) {
        reject();
        return;
      }

      axios
        .get('https://web2.holdigaz.ch/espace-client/api/commune_search.php', {
          params: {
            zipCode,
          },
        })
        .then(
          (res) => {
            location.value = res.data;
            resolve(location);
          },
          (err) => {
            /**
             * When a location is found but not available, the API return a 404 error with `isAvailable: false`.
             */

            if (err.response?.data) {
              location.value = err.response.data;
              resolve(location);
              return;
            }

            errors.value.push([
              gettext(
                'Nous ne sommes pas en mesure de valider votre numéro postal actuellement.'
              ),
            ]);
            reject();
          }
        );
    });
  };

  return {
    search,
    location,
    errors,
  };
}
