<template>
  <div :class="['form-group', { 'form-group--invalid': localErrors.length }]">
    <label
      v-if="field"
      :for="id"
      class="field-label pdgt0"
      :class="{ 'visible-sr': hideLabel }"
    >
      {{ field.label }}
      <abbr
        v-if="field.required"
        :title="gettext('Champ requis')"
        class="field-required"
      >
        *
      </abbr>
    </label>
    <slot></slot>
    <div v-if="help" class="field-help">{{ help }}</div>
    <Errors :errors="localErrors" class="mrgt--" />
  </div>
</template>

<script>
import { computed, reactive, provide } from 'vue';

import Errors from './Errors.vue';

export const formGroupKey = Symbol();

export default {
  components: {
    Errors,
  },

  props: {
    field: {
      type: Object,
      default: null,
    },
    help: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const id = computed(() => {
      return props.field ? `field-${props.field.name}` : null;
    });

    const localErrors = computed(() => {
      return Object.keys(props.errors).length ? props.errors : [];
    });

    const group = reactive({
      id: id.value,
      field: props.field,
    });

    provide(formGroupKey, group);

    return {
      id,
      localErrors,
      group,
    };
  },
};
</script>
