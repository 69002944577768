<template>
  <input
    :id="group && group.id"
    :type="type"
    :value="modelValue"
    :name="localField.name"
    class="field field--alt"
    :required="localField.required"
    :placeholder="localField.placeholder"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
import { computed, inject } from 'vue';
import { formGroupKey } from '@/components/forms/FormGroup.vue';

export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    modelValue: {
      required: false,
      type: [Object, String, Number],
      default: null,
    },
    field: {
      required: false,
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const group = inject(formGroupKey, { field: null, id: null });

    const localField = computed(() => {
      return props.field || group.field;
    });

    return {
      group,
      localField,
    };
  },
};
</script>
